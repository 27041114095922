import { AuthorsField } from "./src/fields/authors"
const transitionDelay = 500

export const onClientEntry = () => {
  window.tinacms.fields.add({
    name: "authors",
    Component: AuthorsField,
  })
}
export const shouldUpdateScroll = ({
  routerProps: { location },
  getSavedScrollPosition,
}) => {
  if (location.action === "PUSH") {
    document.getElementById("Top").scrollIntoView()
  }
}
